/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// @import "./assets/sass/style";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
@import "./assets/sass/style.angular.scss";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@ctrl/ngx-emoji-mart/picker';

:root {
  --bs-info: #378ced !important;
  --kt-info: #378ced !important;
  --kt-info-active: #1e7ae3 !important
}

.cursor {
  color: var(--bs-primary);
  cursor: pointer;

}

.btn-outline-primary {
  // font-family: var(--font-lato) !important;
  // font-style: normal;
  // font-weight: 700;
  // font-size: 14px;
  // line-height: 148%;
  // --bs-btn-padding-x: 2rem !important;
  // --bs-btn-padding-y: 1rem !important;
  color: #fff !important;
  box-shadow: 0 0 0 1px inset #9945FF, 0 0 0 2px inset #14F195
}

.btn-outline-primary:hover {
  box-shadow: none !important;
  background: linear-gradient(250deg, #14F195 -47.16%, #9945FF 140.55%);
  color: #fff !important;
}

.swal2-popup {
  background-color: var(--kt-body-bg) !important;
  padding: 2rem !important;
  border-radius: 0.475rem !important;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background: linear-gradient(250deg, #14F195 -47.16%, #9945FF 140.55%) !important;
  color: #fff;
  font-size: 1em;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px #749af880 !important;
}


// Scroll bar section
* {
  scrollbar-width: thin;
  // scrollbar-color: var(--bs-primary) rgb(26, 23, 23);
}

*::-webkit-scrollbar {
  width: 7px;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 1ex 0 0 1ex;
  background: #353558;
}


.aside-dark .menu .menu-item .menu-link.active {
  transition: color 0.2s ease;
  // background-color: v;
}

.aside-dark .menu .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #323248 !important;
}

.no-cursor {
  cursor: auto;
}

.img-upload {
  // background: url('./assets//media/avatars/blank.png') no-repeat;
  background: var(--bs-body-bg);
  background-position: center;
  background-size: cover;
  border: 1.27848px solid #5e5d5d;
  box-sizing: border-box;
  box-shadow: 0px 2.55696px 10.8671px rgba(157, 93, 65, 0.38);
  border-radius: 12px;
  width: 200px;
  height: 200px;
  overflow: hidden !important;
  transition: all .5s ease-in-out;
}

.img-upload:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.57), rgba(0, 0, 0, 0.57));
}

input.test {
  display: none !important;
}


.my-pagination {

  .ngx-pagination .current {
    background: var(--bs-primary) !important;
  }

  .ngx-pagination a:hover,
  .ngx-pagination button:hover {
    background: var(--bs-primary);
  }

}

.ngx-pagination .current {
  background: var(--bs-primary) !important;
  color: #fefefe;
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: var(--bs-primary);
}



@media (min-width: 1920px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 90%;
  }
}


.input-group-text {
  /* color: var(--kt-input-group-addon-color); */
  background-color: var(--kt-body-bg);
  border: none !important;
  padding: 0 !important;
}


.NgxEditor {
  background: var(--bs-card-bg) !important;
  color: var(--bs-card-color) !important;
  background-clip: padding-box;
  border-radius: 4px;
  border: 1pxsolidrgba(0, 0, 0, .2);
  position: relative;
}

.NgxEditor__Popup {
  background-color: var(--bs-card-bg) !important;
  color: #000 !important;
}

.NgxEditor__MenuBar {
  display: flex;
  flex-wrap: wrap;
  padding: 3px;
  cursor: default;
  background-color: var(--kt-input-bg) !important;

}

.NgxEditor__Wrapper {
  //  background-color: var(--bs-card-bg) !important;
  border: 1px solid var(--kt-input-border-color) !important;
}

.NgxEditor__Content {
  color: var(--kt-input-color) !important;
  background-color: var(--kt-input-bg) !important;
  border: 1px solid var(--kt-input-border-color) !important;
}

.NgxEditor__MenuItem {
  cursor: pointer !important;
}

.NgxEditor__MenuItem:hover {
  background-color: #79797947 !important;
}

.NgxEditor__Dropdown:hover {
  background-color: #79797947 !important;
}

.NgxEditor__Dropdown .NgxEditor__Dropdown--DropdownMenu {
  box-shadow: #3c404326 0 2px 6px 2px;
  background-color: var(--kt-input-bg) !important;
}

.NgxEditor__Dropdown .NgxEditor__Dropdown--Item:hover {
  background-color: #79797947 !important;
}

.NgxEditor__Dropdown .NgxEditor__Dropdown--Selected,
.NgxEditor__Dropdown .NgxEditor__Dropdown--Open {
  color: var(--kt-primary) !important;
  background-color: var(--kt-input-bg) !important
}

.NgxEditor__MenuItem.NgxEditor__MenuItem--Active,
.NgxEditor__MenuItem .NgxEditor__MenuItem--Active {
  color: var(--kt-primary) !important;
  background-color: var(--kt-input-bg) !important
}

.ng2-tag-input__text-input {
  color: var(--kt-input-color) !important;
  background-color: var(--kt-input-bg) !important;
  border: 1px solid var(--kt-input-border-color) !important;
  box-shadow: none !important;
}

.ng2-tag-input {
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  transition: all .25s;
  padding: 0.25rem 0;
  min-height: 32px;
  cursor: text;
  border-bottom: 2px solid var(--kt-input-border-color) !important;
}


.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid var(--bs-primary) !important;
  margin-right: 4px;
  background: var(--bs-primary) !important;
  padding: 0 5px;
  color: #fff;
  border-radius: 2px;
  float: left;
  display: flex;
  max-width: 100% !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
  animation: borderscale .2s ease-in;
  background: var(--bs-primary) !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
  box-sizing: content-box;
  content: "";
  color: var(--bs-primary) !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid var(--bs-primary) !important;
  text-align: center;
  transition: all .4s ease;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
  box-sizing: content-box;
  content: "";
  color: var(--bs-primary) !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid var(--bs-primary) !important;
  text-align: center;
  transition: all .4s ease;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid transparent !important;
  border-radius: 5px;
  background: transparent !important;
  // float: right;
}

.form-check-input {
  // background-color: var(--kt-form-check-input-bg);
  border: var(--kt-form-check-input-border);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: none;
}


.table>thead {
  vertical-align: sub !important;
}



.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 1px solid #7e8299 !important;
  width: 100%;
  padding: 0.775rem 3rem 0.775rem 1rem !important;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.52857143;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 0.475rem !important;
  height: 44.2292px !important;
}

@media (min-width: 768px) and (max-width: 1450px) {
  .image-container {
    position: relative;
    width: 300px !important;
    height: 300px !important;
    // border: 1px solid #ccc;
    text-align: center;
    overflow: hidden !important;
    background-color: var(--kt-card-bg) !important;
    border-radius: 6px;
  }

  .image-container2 {
    width: 200px !important;
    height: 200px !important;
    // border: 1px solid #ccc;
    text-align: center;
    overflow: hidden !important;
    background-color: var(--kt-card-bg) !important;
    border-radius: 6px;
    margin-bottom: 24px;
    position: relative;
  }
}


.accordion-item {
  border: none !important;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: transparent !important;
  box-shadow: none !important;
}

.ngb-dp-weekday {
  color: var(--bs-primary) !important;
}


.form-control.invalid,
.form-select.invalid {
  border-color: red !important;
}

.form-control:focus.invalid,
.form-select:focus.invalid {
  border-color: red !important;
}



.btn-custom {
  // background: linear-gradient(54.9deg, #E71E84 -5.68%, #F39028 100.4%);
  background: linear-gradient(250deg, #14F195 -47.16%, #9945FF 140.55%);
  font-family: var(--font-lato) !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 148%;
  /* identical to box height, or 21px */
  color: #fff !important;
  // padding: 10px 26px !important;
  --bs-btn-padding-x: 2rem !important;
  --bs-btn-padding-y: 1rem !important;
}

.btn-custom:hover {
  color: rgb(255, 253, 253);
  background: linear-gradient(250deg, #9945FF -47.16%, #14F195 140.55%)
}

.custom {
  color: var(--bs-primary) !important;
  font-size: .9rem !important;
}

.custom:hover {
  text-decoration: underline !important;
}